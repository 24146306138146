@import "./node_modules/modern-normalize/modern-normalize.css";

@mixin bp($point) {
  $mobile-xs: "(max-width: 375px)";
  $mobile: "(max-width: 480px)";
  $mobile-large: "(max-width: 600px)";
  $tablet: "(max-width: 768px)";
  $tablet-large: "(max-width: 900px)";
  $desktop: "(max-width: 1280px)";
  $desktop-large: "(max-width: 1440px)";

  @if $point == lg-xl {
    @media #{$desktop-large} {
      @content;
    }
  } @else if $point == lg {
    @media #{$desktop} {
      @content;
    }
  } @else if $point == md-xl {
    @media #{$tablet-large} {
      @content;
    }
  } @else if $point == md {
    @media #{$tablet} {
      @content;
    }
  } @else if $point == sm-xl {
    @media #{$mobile-large} {
      @content;
    }
  } @else if $point == sm {
    @media #{$mobile} {
      @content;
    }
  } @else if $point == sm-xs {
    @media #{$mobile-xs} {
      @content;
    }
  }
}

@keyframes pulse-white {
  0% {
    transform: translate(-50%, -50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 237, 224, 0.7);
  }

  70% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 0 10px rgba(255, 237, 224, 0);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 237, 224, 0);
  }
}

:root {
  --c-primary: #2d231a;
  --c-secondary: #994e0e;
  --c-secondary-light: #d7aa8b;
  --c-black: #000000;
  --c-white: #ffffff;
  --c-cream: #f9ebdc;
  --c-cream-light: #ffede0;
  --shadow-color: 34deg 25% 57%;
  --shadow-elevation-low: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.34),
    0px 0.3px 0.3px -1.2px hsl(var(--shadow-color) / 0.34),
    0px 0.8px 0.9px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.36),
    0px 0.6px 0.7px -0.8px hsl(var(--shadow-color) / 0.36),
    0px 1.6px 1.8px -1.7px hsl(var(--shadow-color) / 0.36),
    0px 3.8px 4.3px -2.5px hsl(var(--shadow-color) / 0.36);
  --shadow-elevation-high: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.38),
    0px 1.3px 1.5px -0.4px hsl(var(--shadow-color) / 0.38),
    0px 2.4px 2.7px -0.8px hsl(var(--shadow-color) / 0.38),
    0px 4.3px 4.8px -1.2px hsl(var(--shadow-color) / 0.38),
    -0.1px 7.3px 8.2px -1.7px hsl(var(--shadow-color) / 0.38),
    -0.1px 12px 13.5px -2.1px hsl(var(--shadow-color) / 0.38),
    -0.2px 18.9px 21.3px -2.5px hsl(var(--shadow-color) / 0.38);
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  /* Load system fonts */
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;

  /* Make type rendering look crisper */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Disable auto-enlargement of small text in Safari */
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;

  /* Enable kerning and optional ligatures */
  text-rendering: optimizeLegibility;
  font-size: 0.625em;
  line-height: 1;
  box-sizing: border-box;
}
/* Text meant only for screen readers. */
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}
.hidden {
  display: none !important;
}

body {
  background: var(--c-cream);
  color: var(--c-primary);
  overflow-x: hidden;
  font-family: "Source Sans Pro", sans-serif;
}

@font-face {
  font-family: "Burford Base";
  src: url("./fonts/BurfordBase.woff2") format("woff2"),
    url("./fonts/BurfordBase.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: "Burford Base";
  color: var(--c-primary);
  font-weight: normal;
}

h1 {
  font-size: 7rem;
  line-height: 4rem;
  letter-spacing: -10px;

  @include bp(md-xl) {
    font-size: 8rem;
    line-height: 5.4rem;
    letter-spacing: -10px;
  }
  @include bp(sm-xl) {
    font-size: 5.6rem;
    line-height: 4rem;
    letter-spacing: -6.4px;
  }
}

h2 {
  font-size: 7rem;
  line-height: 5.4rem;
  letter-spacing: -8px;

  @include bp(md-xl) {
    font-size: 6rem;
    line-height: 4.4rem;
    letter-spacing: -8px;
  }

  @include bp(sm-xl) {
    font-size: 5rem;
    line-height: 3.6rem;
    letter-spacing: -4.8px;
  }
}

h3 {
  font-size: 4rem;
  line-height: 3.6rem;
  letter-spacing: -4px;

  @include bp(sm-xl) {
    font-size: 4rem;
    line-height: 3rem;
    letter-spacing: -4px;
  }
}

p {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  line-height: 2.6rem;

  @include bp(sm-xl) {
    font-size: 1.7rem;
    line-height: 2.3rem;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
  margin: 0;
  background: var(--c-secondary);
  color: var(--c-white);
  border: none;
  border-radius: 8px;
  padding: 17px 40px;
  height: 54px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #773808;
  }

  &:focus {
    outline: 0;
    background: #773808;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a:not(.btn) {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    font-size: 2rem;
    line-height: 3rem;

    @include bp(sm-xl) {
      font-size: 1.7rem;
      line-height: 2.3rem;
    }
  }

  &.styled {
    li {
      position: relative;
      padding-left: 2.4rem;
      line-height: 2.6rem;

      & + li {
        margin-top: 16px;
      }

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 12px;
        background: var(--c-secondary);
      }
    }
  }
}

ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;

  li {
    font-size: 1.8rem;
    line-height: 2.8rem;

    & + li {
      margin-top: 8px;
    }

    @include bp(sm-xl) {
      font-size: 1.7rem;
      line-height: 2.3rem;
    }
  }
}

.container {
  max-width: 1248px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
}

.topbar {
  display: flex;
  align-items: center;
  gap: 16px;
  background: var(--c-primary);
  height: 72px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  color: var(--c-cream);

  .grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .countdown {
    color: #ffffff;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    max-width: 420px;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0;
    }

    .time {
      font-weight: 600;
      font-size: 24px;
      line-height: 1;
      color: var(--c-primary);
      background: var(--c-cream);
      padding: 2px 6px 4px;
      border-radius: 4px;
    }

    .time-label {
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.1px;
      font-weight: 600;
      margin-top: 6px;
      text-transform: uppercase;
      opacity: 0.8;
    }
  }

  p {
    text-align: center;
  }

  .btn {
    text-decoration: none;
    height: 40px;
    padding: 12px 24px;
    font-size: 15px;
    letter-spacing: 1px;
    justify-self: center;
  }

  @include bp(md) {
    height: auto;
    padding: 20px 0;

    .container {
      padding: 0 16px;
    }

    .grid {
      flex-wrap: wrap;
      justify-content: center;
    }

    p {
      max-width: 45%;
      font-size: 1.6rem;
      line-height: 1.2;
      text-align: left;
    }

    .btn {
      max-width: 55%;
      font-size: 14px;
      padding: 12px 16px;
    }

    .countdown {
      margin: 0;

      .wrapper {
        flex-direction: row;

        .time {
          font-size: 22px;
        }

        .time-label {
          font-size: 13px;
          margin-top: 0;
          margin-left: 6px;
        }
      }
    }
  }
}

.hero {
  position: relative;
  padding: 56px 0 64px;
  min-height: 480px;

  .bull {
    display: block;
    position: absolute;
    left: 0;
    top: -40px;
    width: 956px;
    height: auto;
    transition: transform 0.4s ease;
    will-change: transform;
    opacity: 0.1;

    @media (min-width: 901px) {
      transform: translateX(-10%);
    }
    @media (min-width: 1200px) {
      transform: translateX(0%);
    }
    @media (min-width: 1441px) {
      transform: translateX(10%);
    }
    @media (min-width: 1800px) {
      transform: translateX(20%);
    }
    @media (min-width: 2200px) {
      transform: translateX(40%);
    }

    @include bp(md-xl) {
      width: auto;
      height: 500px;
      margin: 0 auto;
      top: 70px;
      transform: translateX(-20%);
    }

    @include bp(sm-xl) {
      height: auto;
    }
  }

  .container__left {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .subheading {
    font-size: 2rem;
    line-height: 2rem;
    color: var(--c-secondary);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 12px;
    }
  }

  h1 {
    margin-bottom: 4rem;

    & + p {
      font-size: 26px;
      line-height: 28px;
      letter-spacing: 1px;
      color: var(--c-secondary);
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 2.2rem;
    line-height: 3.2rem;

    strong {
      letter-spacing: 0.4px;
    }
  }

  .video {
    max-width: 650px;
    border-radius: 18px;
    overflow: hidden;
    display: block;
    margin: 32px auto;
    position: relative;

    .video-play-trigger {
      background: #ffede0;
      padding: 0;
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      box-shadow: 0 0 0 0 rgba(255, 237, 224, 1);
      animation: pulse-white 2s infinite;
      border-radius: 40px;
      transition: all 0.2s ease;
      transform: translate(-50%, -50%) scale(1);

      svg {
        margin-right: 0;
        width: 64px;
        height: 64px;
      }
    }
  }

  .subscribe-form-wrapper {
    max-width: 620px;
    width: 100%;
    margin: 24px auto 0;
  }

  .subscribe-form {
    h2 {
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.1;
      margin-bottom: 16px;
    }
    p {
      font-size: 2rem;
      line-height: 2.8rem;
      font-weight: 500;
      max-width: 700px;
      margin: 0 auto;
    }

    .subscribe-form__form {
      max-width: 600px;
      width: 100%;
      margin: 40px auto 0;

      #subscribe-form_blog {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 16px;
      }

      .form-privacy {
        font-size: 16px;
        display: block;
        margin-top: 16px;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .subscribe-form__form__success {
      margin-top: 32px;
      p {
        font-weight: 600;

        & + p {
          margin-top: 16px;
        }

        font-size: 2rem;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    input {
      background: #fffaf3;
      border: 1px solid #a4947f;
      border-radius: 6px;
      font-size: 1.8rem;
      color: var(--c-primary);
      height: 54px;
      padding: 0 20px;

      &[type="text"] {
        grid-column: 1 / span 4;
      }
      &[type="email"] {
        grid-column: 5 / span 4;
      }

      &[type="tel"] {
        grid-column: 1 / span 5;
      }

      &::placeholder {
        opacity: 1;
        color: #52453a;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--c-primary);
        border-color: var(--c-primary);
      }
    }

    .btn {
      grid-column: 6 / span 3;
    }
  }

  @include bp(md-xl) {
    padding-top: 5.6rem;
    text-align: center;

    .container__left {
      margin: 0 auto;
    }
  }

  @include bp(md) {
    min-height: auto;

    .subheading {
      font-size: 2rem;
      line-height: 1.8rem;
    }

    h1 {
      margin-bottom: 3.2rem;

      & + p {
        font-size: 24px;
      }
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
    }

    .subscribe-form {
      margin-top: 24px;

      h2 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
      }
      p {
        font-size: 1.7rem;
        line-height: 2.4rem;
      }

      .subscribe-form__form {
        max-width: 640px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      .subscribe-form__form__success {
        p {
          font-size: 2rem;
        }
      }

      input {
        flex: auto;
        width: 100%;
      }

      .btn {
        width: 100%;
        text-align: center;
        max-width: 100%;
        padding: 17px 24px;
      }
    }
  }

  @include bp(sm-xl) {
    padding: 4rem 0 5.6rem;

    .subheading {
      font-size: 1.7rem;
      margin-bottom: 16px;
    }

    .video {
      margin-top: 24px;
      .video-play-trigger {
        svg {
          width: 48px;
          height: 48px;
        }
      }
    }

    .subscribe-form {
      .subscribe-form__form {
        max-width: 100%;

        #subscribe-form_blog {
          grid-template-columns: 1fr;
        }
      }

      .subscribe-form__form__success {
        p {
          font-size: 2rem;
        }
      }

      input {
        flex: auto;
        width: 100%;
        max-width: 100%;
        font-size: 16px;

        &[type="email"],
        &[type="text"],
        &[type="tel"] {
          grid-column: 1;
        }
      }

      .btn {
        grid-column: 1;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.sponsor {
  padding: 5.6rem 0;
  background: #f2dfc9;

  .grid {
    display: flex;
    align-items: center;
    gap: 32px;
    max-width: 900px;
    margin: 0 auto;

    .grid__item {
      &:nth-child(1) {
        flex-shrink: 0;
      }
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 2.8rem;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @include bp(md) {
    .grid {
      flex-direction: column;
    }
  }
}

.cta {
  padding: 80px 0;
  border-top: 1px solid #dec9b1;
  text-align: center;
  background: var(--c-primary);
  color: var(--c-cream);

  @include bp(sm-xl) {
    padding: 40px 0 64px;
  }

  h2 {
    font-size: 60px;
    letter-spacing: -7px;
    max-width: 600px;
    margin: 0 auto 16px;
    color: inherit;

    @include bp(sm-xl) {
      font-size: 5rem;
      line-height: 3.6rem;
      letter-spacing: -4.8px;
    }
  }

  &.alt {
    h2 {
      font-size: 7rem;
      line-height: 5.4rem;
      letter-spacing: -8px;
      max-width: 100%;
    }
  }

  .subscribe-form-wrapper {
    max-width: 620px;
    width: 100%;
    margin: 24px auto 0;
  }

  .subscribe-form {
    p {
      font-size: 2.2rem;
      line-height: 3rem;
      font-weight: 500;
      max-width: 700px;
      margin: 0 auto;
    }

    .subscribe-form__form {
      max-width: 600px;
      width: 100%;
      margin: 40px auto 0;

      #subscribe-form_blog {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 16px;
      }

      .form-privacy {
        font-size: 16px;
        display: block;
        margin-top: 16px;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .subscribe-form__form__success {
      margin-top: 32px;
      p {
        font-weight: 600;
        & + p {
          margin-top: 16px;
        }

        font-size: 2rem;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    input {
      background: #fffaf3;
      border: 1px solid #a4947f;
      border-radius: 6px;
      font-size: 1.8rem;
      color: var(--c-primary);
      height: 54px;
      padding: 0 20px;

      &[type="text"] {
        grid-column: 1 / span 4;
      }
      &[type="email"] {
        grid-column: 5 / span 4;
      }

      &[type="tel"] {
        grid-column: 1 / span 5;
      }

      &::placeholder {
        opacity: 1;
        color: #52453a;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--c-primary);
        border-color: var(--c-primary);
      }
    }

    .btn {
      grid-column: 6 / span 3;
    }
  }

  @include bp(md) {
    .subscribe-form {
      margin-top: 24px;

      p {
        font-size: 2rem;
        line-height: 2.6rem;
      }

      .subscribe-form__form {
        max-width: 640px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      .subscribe-form__form__success {
        p {
          font-size: 2rem;
        }
      }

      input {
        flex: auto;
        width: 100%;
      }

      .btn {
        width: 100%;
        text-align: center;
        max-width: 100%;
        padding: 17px 24px;
      }
    }
  }

  @include bp(sm-xl) {
    &.alt {
      h2 {
        font-size: 5rem;
        line-height: 3.6rem;
        letter-spacing: -4.8px;
      }
    }

    .subscribe-form {
      .subscribe-form__form {
        max-width: 100%;

        #subscribe-form_blog {
          grid-template-columns: 1fr;
        }
      }

      .subscribe-form__form__success {
        p {
          font-size: 2rem;
        }
      }

      input {
        flex: auto;
        width: 100%;
        max-width: 100%;
        font-size: 16px;

        &[type="email"],
        &[type="text"],
        &[type="tel"] {
          grid-column: 1;
        }
      }

      .btn {
        grid-column: 1;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.logos {
  padding: 4.8rem 0;

  .logo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 1052px;
    margin: 0 auto;
    gap: 80px;
    align-items: center;
    justify-items: center;

    @include bp(md) {
      gap: 40px;
    }

    @include bp(sm-xl) {
      gap: 24px 32px;

      li {
        &:nth-child(1) {
          grid-column: 1/3;
        }
        &:nth-child(2) {
          grid-column: 3/-1;
        }
        &:nth-child(3) {
          grid-column: 1/3;
        }
        &:nth-child(4) {
          grid-column: 3/-1;
        }
      }
    }
  }
}

.radical-health {
  background: var(--c-primary);
  color: var(--c-cream-light);
  padding: 9.6rem 0;

  h2 {
    text-align: center;
    color: inherit;
    margin-bottom: 3.2rem;
  }

  h3 {
    color: inherit;
    text-align: center;
  }

  p {
    font-size: 2.2rem;
    line-height: 2.8rem;

    & + p {
      margin-top: 2rem;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .container__left {
    text-align: center;
    margin: 0 auto 5.6rem;
    max-width: 690px;

    svg {
      margin: 56px auto 0;
    }
  }

  .container__right {
    p {
      text-align: center;
      font-size: 2rem;
      line-height: 2.8rem;
      max-width: 760px;
      margin: 0 auto;
    }

    ul {
      max-width: 1032px;
      margin: 32px auto 40px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px 40px;

      li {
        position: relative;
        display: flex;
        align-items: center;

        img {
          flex-shrink: 0;
          display: block;
          margin-right: 12px;
          width: 30px;
          height: 30px;
        }

        p {
          font-size: 1.8rem;
          line-height: 2.6rem;
          font-weight: 600;
          letter-spacing: -0.11px;
          max-width: 100%;
          margin: 0;
        }

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  @include bp(md) {
    padding: 5.6rem 0;

    h2 {
      padding: 0 2.4rem;
      margin-bottom: 2.4rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;

      & + p {
        margin-top: 2rem;
      }
    }

    .container__right {
      p {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }

      ul {
        grid-template-columns: 1fr;
        gap: 24px;

        li {
          p {
            font-size: 1.8rem;
            line-height: 2.6rem;
          }
        }
      }
    }
  }
}

.wayfx-start__stories {
  padding: 96px 0;
  background: #f2dfc9;
  color: #2d231a;

  h2 {
    font-size: 70px;
    line-height: 48px;
    letter-spacing: -7.78px;
    text-align: center;
    margin-bottom: 64px;

    br {
      display: none;
    }
  }

  .wayfx-start__stories-slider-wrapper {
    position: relative;

    .slick-dots {
      list-style: none;
      margin: 0 40px 0 0;
      padding: 0;
      position: unset;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      width: auto;

      li {
        margin: 0;
        padding: 0;
        width: 18px;
        height: 18px;

        & + li {
          margin-left: 12px;
        }

        button {
          padding: 0;
          width: 18px;
          height: 18px;
          background: #d5c3af;
          transition: all 0.2s ease;
          border-radius: 9px;

          &::before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background: var(--wayfx-primary);
          }
        }
      }
    }

    .wayfx-start__stories-slider-controls {
      display: inline-flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      position: absolute;
      bottom: 24px;

      button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        position: relative;
        transition: all 0.2s ease;

        &.wayfx-slider-previous-arrow {
          bottom: 0;
          left: 0;
          display: none !important;
        }
        &.wayfx-slider-next-arrow {
          bottom: 0;
          left: 0;
        }

        svg {
          path {
            transition: all 0.2s ease;
          }
        }

        &:hover {
          svg {
            path {
              fill: #715f49;
            }
          }
        }
      }
    }
  }

  .wayfx-start__stories-slider {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 72px 24px;
    justify-items: center;
    max-width: 1120px;
    margin: 0 auto;

    /* the slides */
    .slick-slide {
      margin: 0 24px;
    }
    /* the parent */
    .slick-list {
      margin: 0 -24px;
    }

    .wayfx-start__stories-slide {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column-reverse;

      .slide-content {
        max-width: 482px;
        width: 100%;
        position: relative;
        margin-top: 32px;

        &::before {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg width='72' height='62' viewBox='0 0 72 62' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.264 61.128c-4.9653333 0-8.75466667-1.8946667-11.368-5.684-2.61333333-3.7893333-3.92-9.1466667-3.92-16.072C.976 22.2546667 9.07733333 9.44933333 25.28.956l5.488 9.996c-5.488 3.136-9.5713333 6.5986667-12.25 10.388-2.6786667 3.7893333-4.018 8.5586667-4.018 14.308.392-.1306667.98-.196 1.764-.196 3.528 0 6.566 1.078 9.114 3.234 2.548 2.156 3.822 5.1286667 3.822 8.918 0 4.1813333-1.2086667 7.4806667-3.626 9.898s-5.5206667 3.626-9.31 3.626Zm40.572 0c-4.9653333 0-8.7546667-1.8946667-11.368-5.684-2.6133333-3.7893333-3.92-9.1466667-3.92-16.072 0-17.1173333 8.1013333-29.92266667 24.304-38.416l5.488 9.996c-5.488 3.136-9.5713333 6.5986667-12.25 10.388-2.6786667 3.7893333-4.018 8.5586667-4.018 14.308.392-.1306667.98-.196 1.764-.196 3.528 0 6.566 1.078 9.114 3.234 2.548 2.156 3.822 5.1286667 3.822 8.918 0 4.1813333-1.2086667 7.4806667-3.626 9.898s-5.5206667 3.626-9.31 3.626Z' fill='%232D231A' opacity='.069613'/%3E%3C/svg%3E");
          background-size: 70px auto;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          left: -24px;
          width: 70px;
          height: 60px;
        }

        p {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0.13px;
          color: inherit;
          font-weight: 600;
          text-align: left;

          & + p {
            margin-top: 16px;
          }

          a {
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }

        cite {
          font-style: normal;
          display: flex;
          align-items: center;
          margin-top: 24px;

          strong {
            font-size: 20px;
            line-height: 1;

            a {
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }
          }

          span {
            display: flex;
            align-items: center;
            margin-left: 8px;
            font-size: 15px;

            svg {
              margin-right: 6px;
            }
          }
        }

        &.small-text {
          p {
            font-size: 21px;
            line-height: 28px;
            letter-spacing: 0.1px;
          }
        }
      }

      .slide-img {
        max-width: 482px;
        width: 100%;
        height: 482px;

        img {
          border-radius: 12px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 1140px) {
    .wayfx-start__stories-slider-wrapper {
      .wayfx-start__stories-slider-controls {
        bottom: -60px;
      }
    }

    .wayfx-start__stories-slider {
      grid-template-columns: 1fr;
      gap: 56px;

      .wayfx-start__stories-slide {
        .slide-content {
          p {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: -0.1px;
          }
        }
      }
    }
  }

  @include bp(md-xl) {
    padding: 64px 0 56px;

    h2 {
      font-size: 50px;
      line-height: 40px;
      letter-spacing: -5.6px;
      margin-bottom: 40px;

      br {
        display: block;
      }
    }

    .wayfx-start__stories-slider-wrapper {
      position: relative;

      .slick-dots {
        list-style: none;
        margin: 0;
      }

      .wayfx-start__stories-slider-controls {
        display: flex;
        justify-content: center;
        position: unset;
        bottom: 0;
        margin-top: 56px;

        button {
          &.wayfx-slider-next-arrow {
            top: 45%;
            transform: translateY(-50%);
            bottom: unset;
            left: unset;
            position: absolute;
            right: -20px;
            z-index: 2;

            svg {
              width: 40px;
              height: 40px;

              path {
                fill: #a4520d;
              }
            }
          }

          &.wayfx-slider-previous-arrow {
            display: block !important;
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            left: -20px;

            svg {
              width: 40px;
              height: 40px;

              path {
                fill: #a4520d;
              }
            }
          }
        }
      }
    }

    .wayfx-start__stories-slider {
      .wayfx-start__stories-slide {
        flex-direction: column-reverse;

        .slide-content {
          max-width: 482px;
          margin: 0 auto;

          &::before {
            background-size: 47px auto;
            top: 0;
            left: -16px;
            width: 47px;
            height: 40px;
          }

          p {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.1px;
            margin-bottom: 20px;
          }

          cite {
            strong {
              font-size: 18px;
            }
          }

          &.small-text {
            p {
              font-size: 18px;
              line-height: 26px;
              letter-spacing: -0.1px;
            }
          }
        }

        .slide-img {
          margin: 0 auto 32px;
          max-height: 330px;
        }
      }
    }
  }
}

.what-you-get {
  padding: 9.6rem 0;
  color: var(--c-primary);

  @include bp(sm-xl) {
    padding: 6.4rem 0;
  }

  h2 {
    text-align: center;
    max-width: 748px;
    margin: 0 auto 64px;
    color: var(--c-primary);

    @include bp(sm-xl) {
      margin: 0 auto 32px;
    }
  }

  ul {
    max-width: 1032px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    li {
      position: relative;
      padding-left: 59px;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 3rem;
      letter-spacing: -0.11px;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='32' viewBox='0 0 43 32'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='88.003%25' x2='11.05%25' y1='88.003%25' y2='11.052%25'%3E%3Cstop offset='10.8%25' stop-color='%230D7044'/%3E%3Cstop offset='43.3%25' stop-color='%2311945A'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='92.598%25' x2='7.401%25' y1='7.401%25' y2='92.602%25'%3E%3Cstop offset='0%25' stop-color='%232BC07F'/%3E%3Cstop offset='100%25' stop-color='%2321B876'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none'%3E%3Cpath fill='url(%23a)' d='M14.2619429 31.4697143L.53028571 17.7380571c-.70765714-.7076571-.70765714-1.8541714 0-2.5618285L4.2048 11.5017143c.70765714-.7076572 1.85417143-.7076572 2.56182857 0L20.4982857 25.2333714c.7076572.7076572.7076572 1.8541715 0 2.5618286l-3.6745143 3.6745143c-.7076571.7067428-1.8541714.7067428-2.5618285 0z'/%3E%3Cpath fill='url(%23b)' d='M10.5874286 25.2333714L35.2905143.53028571c.7076571-.70765714 1.8541714-.70765714 2.5618286 0L41.5268571 4.2048c.7076572.70765714.7076572 1.85417143 0 2.56182857L16.8237714 31.4697143c-.7076571.7076571-1.8541714.7076571-2.5618285 0L10.5874286 27.7952c-.70674289-.7076571-.70674289-1.8541714 0-2.5618286z'/%3E%3C/g%3E%3C/svg%3E");
        width: 43px;
        height: 32px;
        position: absolute;
        top: 10px;
        left: 0;
      }
    }
  }

  .container {
    display: flex;
  }

  .container__left,
  .container__right {
    flex: 1;
  }

  .container__right {
    padding-left: 56px;

    img {
      border-radius: 6px;
      min-height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }

  .highlight__item {
    padding: 0;
    position: relative;

    &.highlight__item--right {
      &::before {
        right: 0;
      }
    }
  }

  @include bp(md) {
    .container {
      flex-direction: column-reverse;
    }

    .container__right {
      padding-left: 0;
      margin-bottom: 32px;

      img {
        height: auto;
      }
    }
    ul {
      gap: 24px;

      li {
        padding-left: 48px;
        font-size: 1.8rem;
        line-height: 2.6rem;

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='25' viewBox='0 0 32 25'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='88.003%25' x2='11.05%25' y1='88.006%25' y2='11.05%25'%3E%3Cstop offset='10.8%25' stop-color='%230D7044'/%3E%3Cstop offset='43.3%25' stop-color='%2311945A'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='92.599%25' x2='7.399%25' y1='7.401%25' y2='92.602%25'%3E%3Cstop offset='0%25' stop-color='%232BC07F'/%3E%3Cstop offset='100%25' stop-color='%2321B876'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none'%3E%3Cpath fill='url(%23a)' d='M10.8513543 24.1300334L.40380853 13.6821469c-.53841137-.5384289-.53841137-1.4107672 0-1.9491962l2.79570453-2.79579567c.53841138-.53842893 1.41072128-.53842893 1.94913265 0L15.5961915 19.3850415c.5384113.5384289.5384113 1.4107672 0 1.9491962l-2.7957046 2.7957957c-.5384113.5377333-1.4107212.5377333-1.9491326 0z'/%3E%3Cpath fill='url(%23b)' d='M7.87304091 19.3453825L26.811938.40689123c.5425333-.54252164 1.4215213-.54252164 1.9640546 0L31.5931 3.22393836c.5425333.54252165.5425333 1.42149082 0 1.96401246L12.6542029 24.1264421c-.5425332.5425216-1.4215212.5425216-1.9640545 0L7.87304091 21.309395c-.54183232-.5425217-.54183232-1.4214908 0-1.9640125z'/%3E%3C/g%3E%3C/svg%3E");
          width: 32px;
          height: 24px;
          top: 12px;
        }
      }
    }
  }
}

.wayfx-start__tribe {
  padding: 88px 0 112px;
  border-top: 1px solid #dec9b1;

  h2 {
    font-size: 70px;
    line-height: 48px;
    letter-spacing: -7.78px;
    text-align: center;
    margin-bottom: 24px;

    & + p {
      text-align: center;
      font-size: 24px;
      line-height: 1.2;
      letter-spacing: -0.12px;
      font-weight: 600;
      margin: 0 auto 24px;
    }
  }

  p {
    font-size: 22px;
    line-height: 30px;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .wayfx-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    margin-top: 48px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 10px;
    }
  }

  @include bp(md) {
    padding: 56px 0 80px;

    h2 {
      font-size: 50px;
      line-height: 38px;
      letter-spacing: -5.6px;
      margin-bottom: 16px;
      padding: 0 16px;

      & + p {
        font-size: 22px;
        letter-spacing: -0.1px;
        padding: 0 32px;
      }
    }

    p {
      font-size: 20px;
      line-height: 28px;
    }

    .wayfx-grid {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 32px;
    }
  }
}

footer {
  padding: 8.8rem 0;
  text-align: center;
  background: var(--c-primary);
  color: var(--c-cream-light);

  h2 {
    color: inherit;
    margin-bottom: 32px;
  }

  .subscribe-form-wrapper {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }

  .subscribe-form {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;

    p {
      font-size: 2.2rem;
      line-height: 3rem;
      font-weight: 500;
      max-width: 600px;
      margin: 0 auto 24px;

      & + p {
        margin-bottom: 24px;
        font-weight: bold;
      }
    }

    .subscribe-form__form {
      width: 100%;

      #subscribe-form_blog {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 16px;
      }

      .form-privacy {
        font-size: 16px;
        display: block;
        margin-top: 16px;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .subscribe-form__form__success {
      p {
        & + p {
          margin-top: 16px;
        }

        font-size: 2rem;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    input {
      background: #fffaf3;
      border: 1px solid #a4947f;
      border-radius: 6px;
      font-size: 1.8rem;
      color: var(--c-primary);
      height: 54px;
      padding: 0 20px;

      &[type="text"] {
        grid-column: 1 / span 4;
      }
      &[type="email"] {
        grid-column: 5 / span 4;
      }

      &[type="tel"] {
        grid-column: 1 / span 5;
      }

      &::placeholder {
        opacity: 1;
        color: #52453a;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--c-primary);
        border-color: var(--c-primary);
      }
    }

    .btn {
      grid-column: 6 / span 3;
    }
  }

  @include bp(md) {
    .subscribe-form {
      p {
        font-size: 2rem;
        line-height: 2.6rem;
      }

      .subscribe-form__form {
        max-width: 640px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      .subscribe-form__form__success {
        p {
          font-size: 2rem;
        }
      }

      input {
        flex: auto;
        width: 100%;
      }

      .btn {
        width: 100%;
        text-align: center;
        max-width: 100%;
        padding: 17px 24px;
      }
    }
  }

  @include bp(sm-xl) {
    padding: 6.4rem 0;

    .subscribe-form {
      .subscribe-form__form {
        max-width: 100%;

        #subscribe-form_blog {
          grid-template-columns: 1fr;
        }
      }

      .subscribe-form__form__success {
        p {
          font-size: 2rem;
        }
      }

      input {
        flex: auto;
        width: 100%;
        max-width: 100%;
        font-size: 16px;

        &[type="email"],
        &[type="text"],
        &[type="tel"] {
          grid-column: 1;
        }
      }

      .btn {
        grid-column: 1;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
.animated {
  opacity: 0;
  visibility: hidden;
  transition: 800ms cubic-bezier(0.23, 1, 0.32, 1);

  &.inview {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }
}

.fadeInUp {
  transform: translateY(50px);
}

.fadeInDown {
  transform: translateY(-50px);
}

.fadeInLeft {
  transform: translateX(25px);
}

.fadeInRight {
  transform: translateX(-25px);
}

.zoomIn {
  transform: scale(0.7);
}
